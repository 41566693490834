import Vue from "vue";

/*TITLE*/
document.title = "Bazan Residencial | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Bazan Residencial";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Bazan Residencial";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_home-tannheim--20230112110153.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-ppal_forest-natural_matika-bone_deco-bremen-roble-natural--20230112110146.jpg";
Vue.prototype.$image_bath2 = "banyo-2_forest-maple_glaciar_deco-bremen-roble-natural--20230112110118.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_home-tannheim--20230112110153.jpg",
  },
  {
    src: "salon_home-tirol--20230112110101.jpg",
  },
  {
    src: "salon_home-salzburg--20230112110108.jpg",
  },
  {
    src: "salon_home-kaprun--20230112110115.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-ppal_forest-natural_matika-bone_deco-bremen-roble-natural--20230112110146.jpg",
  },
  {
    src: "banyo-ppal_forest-cognac_urban-natural-nature--20230112110154.jpg",
  },
  {
    src: "banyo-ppal_forest-acero_vela-smoked_limit-vela-smoked--20230112110101.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2_forest-maple_glaciar_deco-bremen-roble-natural--20230112110118.jpg",
  },
  {
    src: "banyo-2_forest-cognac_urban-natural-nature--20230112110141.jpg",
  },
  {
    src: "banyo-2_forest-arce_bianco-carrara--20230112110147.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=escritora%20emilia%20pardo%20bazán%20&%20escritor%20conde%20de%20zamora",
    text: "Escritora Emilia Pardo Bazán  &  Escritor Conde de Zamora",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avd%20gran%20capitán%2046",
    text: "Avd Gran Capitán 46",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:697480336",
    text: "697480336",
  },
  {
    icon: "mdi-email",
    link: "mailto:gada@gadagestion.es",
    text: "gada@gadagestion.es",
  },
];
